// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// @import '~bootstrap/dist/css/bootstrap.min.css';


.basic-container {
  padding: 30px;
}
html, body { height: 100vh; }
body { margin: 0; font-family: "Inter"; background-color: #151515; }

